html {
  font-size: 10px;
}

.upload-tool-app {
  text-align: left;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.uta-header {
  min-height: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
  justify-content: center;
  color: #15113b;
  width: 100%;
  z-index: 5;
  background-color: #ffffff;
  padding: 15px 0;
}

.uta-title {
  margin: 0 0 0.5em 0;
  font-size: 1.5em;
}

.uta-link {
  color: #09d3ac;
}

.uta-main {
  min-height: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1em 0;
  box-sizing: border-box;
}

.uta-advanced-view-labels {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  line-height: 20px;
  font-size: 1.1rem;
  width: 100%;
  font-family: sans-serif;
}

.uta-upload-adjust {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1030;
}

.uta-buttons {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 55%;
  border: solid 1px #ddd;
  margin-top: 10px;

  .uta-add-to-cart-button {
    margin: 0 20px 12px 0;
  }

  .uta-cancel-order {
    margin: 0 0 12px 20px;
  }
}

.uta-error {
  font-size: 1.2rem;
}

.uta-errors-list {
  position: absolute;
  opacity: 0;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  transition: opacity 0.1s ease-in;
  background-color: white;
  color: red;
  border: solid 1px red;
  margin: 4rem 0 0 0;
}

.uta-errors-list.uta-visible {
  opacity: 1;
}

.uta-errors-badge {
  opacity: 0;
  background-color: red;
  border: solid 3px white;
  padding: 0.5rem;
  border-radius: 50px;
  height: 0.3rem;
  width: 0.3rem;
  text-align: center;
  display: block;
  position: relative;
  left: -8px;
  top: 13px;
}

.uta-errors-badge.uta-visible {
  opacity: 1;
}

.uta-buttons-header {
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid 1px #ddd;
  background-color: #f0f0f0;
  box-sizing: border-box;
  font-family: sans-serif;

  h3 {
    font-size: 2.5rem;
    margin: 1rem;
    padding-left: 15px;
  }
}

.uta-selectors {
  width: 100%;
  justify-content: space-around;
  text-align: center;
}

.uta-selectors,
.uta-actions,
.uta-advanced-view {
  margin: 10px 0;
  display: flex;
  flex-flow: column nowrap;
  font-size: 1rem;
}

.uta-compact .uta-selectors,
.uta-compact .uta-actions,
.uta-compact .uta-advanced-view {
  margin: 5px 0;
}

.uta-toggle-advanced-view {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  height: 5em;

  .uta-label {
    margin-right: 0.5em;
    padding: 0.6em 0.8em;
    font-family: sans-serif;
    font-size: 1.2rem;
  }
}

.uta-actions {
  align-items: flex-end;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
}

.uta-advanced-view.uta-label {
  font-size: 13px;
  margin-right: 0.5em;
}

.uta-advanced-view {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.uta-selector {
  font-family: sans-serif;
  outline: none;
}

.uta-quantity {
  width: 12em;
}

.uta-button {
  border-radius: 1.2rem;
  border: solid 1px #ddd;
  background-color: #eee;
  padding: 0.6em 0.8em;
  margin: 0 5px;
  font-weight: 600;
}

.uta-button.uta-selector {
  min-width: 12em;
}

.uta-compact .uta-button.uta-selector {
  font-size: 1.2rem;
}

.uta-drop-zone {
  border: dashed 7px #00aeef;
  padding: 0 1em;
}

.uta-drop-zone .uta-text {
  margin: 0;
  padding: 1em;
  font-size: 2.8rem;
  text-align: center;
  font-family: sans-serif;
}

.uta-missing {
  background-color: grey;
}

.uta-selected {
  color: white !important;
}

.uta-selected.orderNr {
  background-color: darkgreen;
}

.uta-selected.artNr {
  background-color: darkblue;
}

.uta-selected.qty {
  background-color: darkgoldenrod;
}

.uta-selected.artRef {
  background-color: darkred;
}

/* default button colour values */
.uta-clicked,
.uta-selected {
  --field-0-color: darkgreen;
  --field-1-color: darkblue;
  --field-2-color: darkgoldenrod;
  --field-3-color: darkred;
  --field-4-color: darkcyan;
  --field-5-color: darkkhaki;
  --field-6-color: darkorange;
  --field-7-color: darkseagreen;
  --field-8-color: slategray;
  --field-9-color: tomato;
  --field-10-color: dodgerblue;
  --field-11-color: limegreen;
  --field-12-color: salmon;
  --field-13-color: thistle;
  --field-14-color: powderblue;
  --field-15-color: palegreen;
  --field-16-color: darkturquoise;
  --field-17-color: firebrick;
  --field-18-color: mediumpurple;
  --field-19-color: peru;
}

.uta-selected.field_0 {
  background-color: var(--field-0-color, darkgreen);
}

.uta-selected.field_1 {
  background-color: var(--field-1-color, darkblue);
}

.uta-selected.field_2 {
  background-color: var(--field-2-color, darkgoldenrod);
}

.uta-selected.field_3 {
  background-color: var(--field-3-color, darkred);
}

.uta-selected.field_4 {
  background-color: var(--field-4-color, darkcyan);
}

.uta-selected.field_5 {
  background-color: var(--field-5-color, darkkhaki);
}

.uta-selected.field_6 {
  background-color: var(--field-6-color, darkorange);
}

.uta-selected.field_7 {
  background-color: var(--field-7-color, darkseagreen);
}

.uta-selected.field_8 {
  background-color: var(--field-8-color, slategray);
}

.uta-selected.field_9 {
  background-color: var(--field-9-color, tomato);
}

.uta-selected.field_10 {
  background-color: var(--field-10-color, dodgerblue);
}

.uta-selected.field_11 {
  background-color: var(--field-11-color, limegreen);
}

.uta-selected.field_12 {
  background-color: var(--field-12-color, salmon);
}

.uta-selected.field_13 {
  background-color: var(--field-13-color, thistle);
}

.uta-selected.field_14 {
  background-color: var(--field-14-color, powderblue);
}

.uta-selected.field_15 {
  background-color: var(--field-15-color, palegreen);
}

.uta-selected.field_16 {
  background-color: var(--field-16-color, darkturquoise);
}

.uta-selected.field_17 {
  background-color: var(--field-17-color, firebrick);
}

.uta-selected.field_18 {
  background-color: var(--field-18-color, mediumpurple);
}

.uta-selected.field_19 {
  background-color: var(--field-19-color, peru);
}

/* selected labels */
.uta-selected-label.field_0 {
  background-color: rgba(darkgreen, 0.2);
}

.uta-selected-label.field_1 {
  background-color: rgba(darkblue, 0.2);
}

.uta-selected-label.field_2 {
  background-color: rgba(darkgoldenrod, 0.2);
}

.uta-selected-label.field_3 {
  background-color: rgba(darkred, 0.2);
}

.uta-selected-label.field_4 {
  background-color: rgba(darkcyan, 0.2);
}

.uta-selected-label.field_5 {
  background-color: rgba(darkkhaki, 0.2);
}

.uta-selected-label.field_6 {
  background-color: rgba(darkorange, 0.2);
}

.uta-selected-label.field_7 {
  background-color: rgba(darkseagreen, 0.2);
}

.uta-selected-label.field_8 {
  background-color: rgba(slategray, 0.2);
}

.uta-selected-label.field_9 {
  background-color: rgba(tomato, 0.2);
}

.uta-selected-label.field_10 {
  background-color: rgba(dodgerblue, 0.2);
}

.uta-selected-label.field_11 {
  background-color: rgba(limegreen, 0.2);
}

.uta-selected-label.field_12 {
  background-color: rgba(salmon, 0.2);
}

.uta-selected-label.field_13 {
  background-color: rgba(thistle, 0.2);
}

.uta-selected-label.field_14 {
  background-color: rgba(powderblue, 0.2);
}

.uta-selected-label.field_15 {
  background-color: rgba(palegreen, 0.2);
}

.uta-selected-label.field_16 {
  background-color: rgba(darkturquoise, 0.2);
}

.uta-selected-label.field_17 {
  background-color: rgba(firebrick, 0.2);
}

.uta-selected-label.field_18 {
  background-color: rgba(mediumpurple, 0.2);
}

.uta-selected-label.field_19 {
  background-color: rgba(peru, 0.2);
}

// button clicked for the first time
.uta-selector.field_0.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-0-color, darkgreen);
  box-shadow: 0px 0px 10px 2px var(--field-0-color, darkgreen);
}

.uta-selector.field_1.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-1-color, darkblue);
  box-shadow: 0px 0px 10px 2px var(--field-1-color, darkblue);
}

.uta-selector.field_2.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-2-color, darkgoldenrod);
  box-shadow: 0px 0px 10px 2px var(--field-2-color, darkgoldenrod);
}

.uta-selector.field_3.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-3-color, darkred);
  box-shadow: 0px 0px 10px 2px var(--field-3-color, darkred);
}

.uta-selector.field_4.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-4-color, darkcyan);
  box-shadow: 0px 0px 10px 2px var(--field-4-color, darkcyan);
}

.uta-selector.field_5.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-5-color, darkkhaki);
  box-shadow: 0px 0px 10px 2px var(--field-5-color, darkkhaki);
}

.uta-selector.field_6.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-6-color, darkorange);
  box-shadow: 0px 0px 10px 2px var(--field-6-color, darkorange);
}

.uta-selector.field_7.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-7-color, darkseagreen);
  box-shadow: 0px 0px 10px 2px var(--field-7-color, darkseagreen);
}

.uta-selector.field_8.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-8-color, slategray);
  box-shadow: 0px 0px 10px 2px var(--field-8-color, slategray);
}

.uta-selector.field_9.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-9-color, tomato);
  box-shadow: 0px 0px 10px 2px var(--field-9-color, tomato);
}

.uta-selector.field_10.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-10-color, dodgerblue);
  box-shadow: 0px 0px 10px 2px var(--field-10-color, dodgerblue);
}

.uta-selector.field_11.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-11-color, limegreen);
  box-shadow: 0px 0px 10px 2px var(--field-11-color, limegreen);
}

.uta-selector.field_12.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-12-color, salmon);
  box-shadow: 0px 0px 10px 2px var(--field-12-color, salmon);
}

.uta-selector.field13.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-13-color, thistle);
  box-shadow: 0px 0px 10px 2px var(--field-13-color, thistle);
}

.uta-selector.field_14.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-14-color, powderblue);
  box-shadow: 0px 0px 10px 2px var(--field-14-color, powderblue);
}

.uta-selector.field_15.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-15-color, palegreen);
  box-shadow: 0px 0px 10px 2px var(--field-15-color, palegreen);
}

.uta-selector.field_16.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-16-color, darkturquoise);
  box-shadow: 0px 0px 10px 2px var(--field-16-color, darkturquoise);
}

.uta-selector.field_17.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-17-color, firebrick);
  box-shadow: 0px 0px 10px 2px var(--field-17-color, firebrick);
}

.uta-selector.field_18.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-18-color, mediumpurple);
  box-shadow: 0px 0px 10px 2px var(--field-18-color, mediumpurple);
}

.uta-selector.field_19.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-19-color, peru);
  box-shadow: 0px 0px 10px 2px var(--field-19-color, peru);
}

// button already selected and clicked
.uta-selected.field_0.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-0-color, darkgreen);
  box-shadow: 0px 0px 10px 2px var(--field-0-color, darkgreen);
}

.uta-selected.field_1.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-1-color, darkblue);
  box-shadow: 0px 0px 10px 2px var(--field-1-color, darkblue);
}

.uta-selected.field_2.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-2-color, darkgoldenrod);
  box-shadow: 0px 0px 10px 2px var(--field-2-color, darkgoldenrod);
}

.uta-selected.field_3.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-3-color, darkred);
  box-shadow: 0px 0px 10px 2px var(--field-3-color, darkred);
}

.uta-selected.field_4.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-4-color, darkcyan);
  box-shadow: 0px 0px 10px 2px var(--field-4-color, darkcyan);
}

.uta-selected.field_5.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-5-color, darkkhaki);
  box-shadow: 0px 0px 10px 2px var(--field-5-color, darkkhaki);
}

.uta-selected.field_6.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-6-color, darkorange);
  box-shadow: 0px 0px 10px 2px var(--field-6-color, darkorange);
}

.uta-selected.field_7.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-7-color, darkseagreen);
  box-shadow: 0px 0px 10px 2px var(--field-7-color, darkseagreen);
}

.uta-selected.field_8.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-8-color, slategray);
  box-shadow: 0px 0px 10px 2px var(--field-8-color, slategray);
}

.uta-selected.field_9.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-9-color, tomato);
  box-shadow: 0px 0px 10px 2px var(--field-9-color, tomato);
}

.uta-selected.field_10.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-10-color, dodgerblue);
  box-shadow: 0px 0px 10px 2px var(--field-10-color, dodgerblue);
}

.uta-selected.field_11.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-11-color, limegreen);
  box-shadow: 0px 0px 10px 2px var(--field-11-color, limegreen);
}

.uta-selected.field_12.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-12-color, salmon);
  box-shadow: 0px 0px 10px 2px var(--field-12-color, salmon);
}

.uta-selected.field13.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-13-color, thistle);
  box-shadow: 0px 0px 10px 2px var(--field-13-color, thistle);
}

.uta-selected.field_14.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-14-color, powderblue);
  box-shadow: 0px 0px 10px 2px var(--field-14-color, powderblue);
}

.uta-selected.field_15.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-15-color, palegreen);
  box-shadow: 0px 0px 10px 2px var(--field-15-color, palegreen);
}

.uta-selected.field_16.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-16-color, darkturquoise);
  box-shadow: 0px 0px 10px 2px var(--field-16-color, darkturquoise);
}

.uta-selected.field_17.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-17-color, firebrick);
  box-shadow: 0px 0px 10px 2px var(--field-17-color, firebrick);
}

.uta-selected.field_18.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-18-color, mediumpurple);
  box-shadow: 0px 0px 10px 2px var(--field-18-color, mediumpurple);
}

.uta-selected.field_19.uta-clicked {
  -webkit-box-shadow: 0px 0px 10px 2px var(--field-19-color, peru);
  box-shadow: 0px 0px 10px 2px var(--field-19-color, peru);
}

.uta-similar {
  background-color: #aaffaa;
  color: #282c34;
}

.uta-interactive:hover {
  cursor: pointer;
}

.uta-interactive span.t:hover,
.uta-interactive div.t:hover {
  background-color: #282c34;
  color: white !important;
  cursor: pointer;
}

.uta-cart {
  background-color: #777;
  color: white;
  border-radius: 7px;
  text-align: left;
  padding: 1em;
  font-size: 1.5rem;
}

.uta-upload-error {
  color: #db341a;
  font-size: 1.8rem;
}

.uta-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  margin-bottom: 50px;
}

.uta-spinner:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #00aeef;
  border-color: #00aeef transparent #00aeef transparent;
  animation: spinner 1.2s linear infinite;
}

.uta-interactive-divs {
  display: flex;
}

.uta-selection-checkbox {
  z-index: 1000;
  position: absolute;
}

.uta-selection-checkbox .column {
  position: absolute;
}

.uta-selection-checkbox .column .form {
  z-index: 1000;
  position: absolute;
  background-color: #ffffff;
  border: 2px solid #00aeef;
  border-radius: 5px;
  padding: 3px;
  display: none;
}

.uta-selection-checkbox .column .form.uta-clicked {
  display: block !important;
}

.uta-selection-checkbox .column .form .checkbox {
  display: flex;
  font-weight: 900;
}

.uta-selection-checkbox .column .form .checkbox .form-check-label {
  display: flex;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
  white-space: nowrap;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uta-tut-highlighted {
  box-shadow: 0px 0px 20px #ee3b33;
  animation: tut-highlight 0.8s linear infinite alternate;
}

@keyframes tut-highlight {
  0% {
    box-shadow: 0px 0px 0px #ee3b33;
  }
  100% {
    box-shadow: 0px 0px 20px #ee3b33;
  }
}

.uta-toggle-tutorial {
  font-size: 1.1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: sans-serif;
  padding-right: 21px;
  color: black;
}

.uta-tutorial {
  width: 55%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  font-size: 2rem;
  height: 19em;
  background-color: #ffffff;
  padding: 1em;
  box-sizing: border-box;
  border: solid 1px #ddd;
  font-family: sans-serif;
  color: #a3a6b4;

  .uta-steps {
    font-size: 1em;
    padding: 0 1em;
    margin: 0;

    .uta-current {
      font-weight: 600;
      color: #000000;
    }
  }

  .uta-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  }

  .uta-next {
    border: solid 1px #ddd;
    font-size: 1.2em;
    padding: 0.6em 0.8em;
    font-weight: 600;
  }
}

.uta-tutorial-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #f0f0f0;
  border: solid 1px #ddd;
  font-family: sans-serif;
  width: 55%;

  h3 {
    font-size: 2.5rem;
    padding-left: 15px;
    margin: 1rem;
  }
}

.uta-sliders {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  .uta-button {
    font-size: 1.4em;
    padding: 0.3em 0.8em;
  }
}

.uta-compact {
  .uta-sliders {
    .uta-button {
      padding: 0;
      height: 2rem;
      width: 4rem;
    }
  }
}

.uta-table {
  display: grid;
  width: 55%;
}

.uta-table table,
th,
td {
  border: 1px solid white;
  border-collapse: collapse;
  padding: 10px;
}

.uta-table {
  th {
    font-size: 1.8em;
    font-family: sans-serif;
    color: #15113b;
  }

  td {
    font-size: 1.5em;
    font-family: sans-serif;
  }
}

.uta-table-quantity {
  text-align: right;
}

.uta-table thead {
  background-color: #dddddd;
}

.uta-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.containerClass {
  width: 100%;
}

.uta-row-selectors {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.uta-row-selectors .col-md-3 {
  padding: 20px;
  flex-basis: 20%;
}

.uta-compact .uta-row-selectors .col-md-3 {
  padding: 5px 0px;
  flex-basis: 0%;
}

@media (max-width: 1200px) {
  .uta-selectors {
    flex-flow: column nowrap;
  }

  .uta-sliders {
    justify-content: center;
  }

  .uta-advanced-view-labels {
    width: auto;
  }

  .uta-button-container {
    margin-bottom: 20px;
  }

  .uta-upload-adjust {
    position: inherit;
  }

  .uta-row-selectors {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
  }
}
